export default {
  'transactions.name': 'Transactions',
  'transactions.transactionName': 'Transaction Name',
  'transactions.internalComment': 'Internal Comment',
  'transactions.customer': 'Customer',
  'transactions.device': 'Device',
  'transactions.amountCoin': 'Total',
  'transactions.user': 'User',
  'transactions.isActive': 'Status',
  'transactions.action': 'Action',
  'transactions.information': 'Transaction Information',
  'transactions.products': 'Products',
  'transactions.productsName': 'Product Name',
  'transactions.quantity': 'Quantity',
  'transactions.productsPrice': 'Price',
  'transactions.actived': 'Actived',
  'transactions.notActive': 'Not active',
  'transactions.totalCoin': 'Total Coin',
  'transactions.totalCoinActive': 'Total Coin Inactive',
  'transactions.totalCoinInactive': 'Total Coin Active',
  'transactions.amountTransactionActive': 'Amout Transaction Inactive',
  'transactions.amountTransactionInactive': 'Amout Transaction Active',
  'transactions.revenue': 'Commit',
  'transactions.type': 'Type',
  'transactions.account.fullName': 'First and last name',
  'transactions.delete': 'Delete transactions',
  'transactions.content.delete': 'Are you sure you want to delete this transaction?',
  'transactions.create': 'Create transactions',
  'users.filter.customer': 'Search customers',
  'users.filter.user': 'Search staff',
  'transactions.time': 'Search date',
  'transactions.type.amountCoin': 'Type transaction',
  'transactions.createDateTime': 'Date created',
  'products.productName': 'Product is name',
};
